import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Container, Typography } from '@mui/material';
import { m } from 'framer-motion';
import { ForbiddenIllustration } from '../assets/illustrations';
import { MotionContainer, varBounce } from '../components/animate';
import { useLocales } from '../locales';
import { useAuthContext } from './useAuthContext';
export default function RoleBasedGuard({ hasContent, roles, children }) {
    const { translate } = useLocales();
    const { user } = useAuthContext();
    const currentRole = user?.role?.code;
    if (typeof roles !== 'undefined' && !roles.includes(currentRole)) {
        return (_jsxs(Container, { component: MotionContainer, sx: { textAlign: 'center' }, children: [_jsx(m.div, { variants: varBounce().in, children: _jsx(Typography, { variant: "h3", paragraph: true, children: `${translate('Permission Denied')}` }) }), _jsx(m.div, { variants: varBounce().in, children: _jsx(Typography, { sx: { color: 'text.secondary' }, children: `${translate('You do not have permission to access this page')}` }) }), _jsx(m.div, { variants: varBounce().in, children: _jsx(ForbiddenIllustration, { sx: { height: 260, my: { xs: 5, sm: 10 } } }) })] }));
    }
    return _jsxs(_Fragment, { children: [" ", children, " "] });
}
